.dcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bodymodal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
}

.headermodal {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);}

.titlemodal {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.UploadClaimTitle {
  width: 100px;
  display: flex;
  flex-direction: row;
  /* font-weight: bold; */
}

.button2:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 238, 255, 0.616),
    0 17px 50px 0 rgba(0, 183, 255, 0.452);
}

.resultbottom {
  text-align: left;
  color: #000;
  width: 20%;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px;
  border-bottom: 0px;
  border-top: 1px;
  border-left: 0px;
  border-right: 0px;
  /* font-weight: bold; */
}

.resultbottomforSummary {
  text-align: left;
  color: #000;
  width: 20%;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px;
  border-top: 1px;
  border-left: 0px;
  border-right: 0px;
}

.resulttableTitle {
  text-align: center;
  color: #000;
  width: 20%;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px;
  border-bottom: 0px;
  border-top: 1px;
  border-left: 0px;
  border-right: 0px;
  font-weight: bold;
}

.resulttableTitleBorder {
  text-align: center;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px;
  border-bottom: 0px;
  border-top: 1px;
  border-left: 0px;
  border-right: 0px;
  font-weight: bold;
  background-color: #70ca69; 
  color: black;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.resulttable {
  text-align: center;
  color: #000;
  width: 20%;
  border-color: #e6e6e6;
  border-style: solid;
  border-width: 1px;
  border-bottom: 1px;
  border-top: 1px;
  /* border-left: 1px;
  border-right: 1px; */
}

.resultbottomNodata {
  text-align: center;
  color: #000;
  width: 20%;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px;
  /* border-bottom: 1px; */
  border-top: 1px;
  border-left: 0px;
  border-right: 0px;
  font-weight: bold;
}

.UnApproveDesign {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
}

.UnApproveTableDesign {
  font-weight: bold;
  border: 1px solid black;
  padding: 4px;
}

.UnApproveTotalTableDesign {
  font-weight: bold;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 4px;
}

.UnApproveTotalLeftTableDesign {
  font-weight: bold;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 4px;
}

.UnApproveTotalRightTableDesign {
  font-weight: bold;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding: 4px;
}

.button3:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.616),
    0 17px 50px 0 rgba(0, 0, 0, 0.452);
}

.ClaimInput {
  display: flex;
  margin-bottom: 1px;
  align-items: center;
}

.loadingimages {
  width: 300px;
  height: 300px;
  border-radius: 50px;
}

/* .backgroundImage {
  background-image: url(./image/Scan_BG_D2.png);
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
  background-color: #FFE0CC;
} */

.ReportAmountTable {
  border: 2px solid rgb(184, 182, 182);
  text-align: center;
  font-weight: bold;
}

.TableLoginIcon {
  align-items: center;
  width: 75px;
}
 .ReportSelectTitle {
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  /* min-width: 25%; */
  width: 75px;
  /* background-color: aqua; */
 }

.ApproverCashOutHeaderdesign {
  display: flex;
  align-items: center;
  width: 50%;
}

.ButtonDesign {
 min-width: 60px;
 margin-right: 2px;
}

.PasswordEye{
  width: 45px;
  height: 45px;
}

.LoginInputText {
  /* text-align: center; */
  height: 30px;
}

.LoginContainer {
  width: 65%;
  /* margin-left: 25%;
  margin-right: 25%; */
  border: 1px solid #ced4da;
  flex-direction: column;
  background-color: white;
}

.ButtonLogin {
  margin-top: 40px;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border-width: 1;
  background-color: black;
  /* border-color: lightblue; */
  justify-content: center;
}

.MainPageDateContainer {
  display: flex;
  align-items: center;
  width: 50%;
}

.ClaimPageDateContainer {
  display: flex;
  align-items: center;
  width: 50%;
}

.CheckBoxDesign {
  font-size: 20px;
  align-items: center;
  display: flex;
}

.LoginUsername {
  width: 80%;
  height: auto;
  padding-top: 6%;
  flex-direction: row;
}

.LoginUsername2 {
  width: 80%;
  height: auto;
  flex-direction: row;
}

.LoginUsernameLabel {
  width: 10%;
  font-weight: bold;
}

.TableFilterListContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TableFilterList {
  width: auto;
  text-align: center;
}

.ApproverCashOutHeaderCover {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  flex-direction: row;
}

.CashoutReceiptTitle {
  /* width: 60%; */
  font-weight: bold;
  font-size: 17px;
}

.HeaderBold {
  font-weight: bold;
}

.Btnsecondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.CssButtonRed { 
  border: none;
  color: white;
  padding: 4px 32px;
  text-align: center; 
  text-decoration: none;
  font-size: 18px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.ButtonHRed {
  background-color: red; 
  color: white;
  border: 2px solid red;
  border-radius: 5px;
}

.ButtonHRed:hover {
  color: white;
  border-radius: 25px;
}


.CssButtonP { 
  border: none;
  color: white;
  padding: 4px 32px;
  text-align: center; 
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.ButtonH {
  background-color: #6c757d; 
  color: white;
  border: 2px solid #6c757d;
  border-radius: 5px;
}

.ButtonH:hover {
  background-color: #40474d;
  color: white;
  border-radius: 25px;
}

.MainButtonShortlong {
  background-color: whitesmoke; 
  color: white;
  border: 2px solid #6c757d;
  border-radius: 5px;
}


.MainButton {
  background-color: whitesmoke; 
  color: black;
  border: 2px solid #6c757d;
  border-radius: 5px;
}

.MainButton:hover {
  background-color: #40474d;
  color: white;
  border-radius: 15px;
}

.PrimaryButton {
  background-color: #0d6efd; 
  color: white;
  border: 2px solid #0d6efd;
  border-radius: 5px;
}

.PrimaryButton:hover {
  background-color: #0d6efd;
  color: white;
  border-radius: 15px;
}

.BorderHideFalse {
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
}

.BorderHideTrue {
  background-color: #70ca69; 
  color: black;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
}



.AlertButton {
  background-color: #ff0f0f; 
  color: white;
  border: 2px solid #ff0f0f;
  border-radius: 5px;
}

.AlertButton:hover {
  background-color: #ff0f0f;
  color: white;
  border-radius: 15px;
}

.DefaultButton {
  border-radius: 5px;
}

.DefaultButton:hover {
  border-radius: 15px;
}

.navbar-brand{
  color: black !important;
}

.navbar-brand:hover {
  color: white !important;
}

.nav-link{
  color: black !important;
}

.nav-link:hover {
  color: white !important;
}

.button {
  display: inline-block;
  border: none;
  color: #ffffff;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 15px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

@media print {
  @page {
    size: A4;
    margin-top: 0;
    margin-bottom: 0;
  }
  .pagebreak {
    clear: both;
    page-break-after: always;
}
  .template { width: auto; left:0; top:0; }

  li { margin: 0 0 10px 20px !important;} 
  .modal-dialog { max-width: 100%; width: 100%;  }
  .modal-body {
    overflow: visible !important;
  }
  .modal-content {
    border: 1px solid white !important;
  }
  .modal-header {
    display: none !important;
  }
  .modal-title {
    display: none !important;
  }
  .non-printable { display: none; }
  .CssButtonP { display: none;}
  .CssButtonRed { display: none;}
  .printable { display: block; }
  .button { display: none; }
  .breaksss { page-break-after: always; }
}

.modal-body-wrapper {
  overflow-y: scroll;
  height: 100vh;
}

.ReceiptContainer {
  margin-bottom: 20px;
  flex-direction: row;
  display: flex;
}

.ClaimPageContainer {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  flex-direction: row;
}

.ClaimSelecteUSerContainer {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ClaimSelectedUser {
  width: 25%;
}

.ModalAlertDesign {
  right: 50px;
  width: 20%;
}
/* width: '70%', left: 250 */
.AlertPage {
  width: 70%;
  left: 250px;
}   

.MoveUpArrow {
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.icon {
  /* margin-top: 50px; */
  animation: scrollTop 0.5s ease infinite alternate;
}

.iconpopup {
  animation: scrollTopPOP 0.5s alternate ease;
}

.scrollDesign {
  position: fixed;
  bottom: 20px;
  right: 3%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #d61414;
  color: #ffffff;
  z-index: 1;
}



.ClaimSearch {
  padding-left: 12px;
  padding-right: 12px;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
}

.pagination a.active {
  background-color: #4A5874;
  color: white;
}

.pagination a:hover:not(.active) { background-color: #ddd; }

@media (max-width: 750px) {
  .ApproverCashOutHeaderdesign {
    width: 100%;
    justify-content: center;
  }
  .ApproverCashOutHeaderCover {
    flex-direction: column;
  }
  .LoginContainer {
    width: 100%;
  }
  .LoginUsername {
    flex-direction: column;
  }
  .LoginUsername2 {
    flex-direction: column;
  }
  .PasswordEye{
    width: 35px;
    height: 35px;
  }
  .LoginUsernameLabel {
    width: 100%;
  }
  .MainPageDateContainer {
    width: 100%;
  }
  .ClaimPageContainer {
    flex-direction: column;
  }
  .ClaimPageDateContainer {
    margin-bottom: 5px;
    flex-direction: column;
    width: 100%;
  }
  .ClaimSelectedUser {
    width: 100%;
  }
  .ClaimSelecteUSerContainer {
    justify-content: center;
    margin-top: 10px;
    width: 100%;
  }
  .ModalAlertDesign{
    right: 30px;
    width: 80%;
  }
  .AlertPage {
    width: 100%;
    left: 0px;
  }
  .ClaimSearch {
    margin-top: 5px;
    width: 50%;
  }
}
